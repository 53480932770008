import Image from "next/image";
import { Clock } from "lucide-react";
import { twMerge } from "tailwind-merge";
import { CoinIcon } from "@/components/icons/coins-icon";
import { StarIcon } from "@/components/icons/star-icon";
import { HandShakeIcon } from "@/components/icons/handshake-icon";
import { ParallelogramIcon } from "@/components/icons/parallelogram-icon";

export function GameCard({
  className,
}: React.HTMLAttributes<HTMLDivElement>): JSX.Element {
  return (
    <div
      className={twMerge(
        "border-[image:url()] relative -m-1 grid min-h-[380px] w-[251px] grid-rows-[auto_1fr_auto] rounded-md",
        className,
      )}
      style={{
        borderImage: "url('/images/borders/legendary-border.png') 180 stretch",
        borderImageWidth: "auto",
        borderImageSlice: "180 310 160 320",
      }}
    >
      <div className="px-[8%] pt-4">
        <Title>Ancestral Legendary Two-Handed Axe</Title>
        <div className="flex items-center justify-between pr-[40px]">
          <div>
            <p className="flex items-center font-diablo text-xs text-gray-50">
              <i className="mr-1 block h-[10px] w-[10px] rounded-full bg-green-100" />
              General Mort
            </p>

            <p className=" flex items-center font-diablo text-xs text-gray-50">
              <Clock className="mr-1 h-[10px] w-[10px]" />
              Listed 4 hours ago
            </p>
          </div>
          <div>
            <p className="flex h-[15px] w-[30px] items-center justify-between border border-green-200 bg-green-200/20 px-1 text-[11px] text-green-200">
              <HandShakeIcon className="h-3 w-3" />7
            </p>
          </div>
        </div>

        <div className="absolute right-[4%] top-3">
          <div className="relative h-[75px] w-[60px]">
            <Image alt="" fill src="/assets/front_end/misc/ax.png" />
          </div>
        </div>
      </div>
      <div className="px-4">
        <div>
          <p className="font-diablo text-xs text-gray-100">925 Item Power</p>
          <Separator className="my-1 w-[110px]" />
          <p className="font-diablo text-xs text-gray-100">
            +56% Damage to Healthy Enemies [56.0]%
          </p>
          <Separator className="my-1" />
        </div>
        <div>
          <p className="flex items-center font-diablo text-xs text-gray-100">
            <GreeaterAffixIcon />
            2,620 Maximum Life
          </p>
          <p className="flex items-center font-diablo text-xs text-gray-100">
            <GreeaterAffixIcon />
            90% Damage
          </p>
          <p className="flex items-center font-diablo text-xs text-gray-100">
            <ParallelogramIcon className="mr-1 h-2 w-2" />
            80% Vulnerable Damage [80 - 100]%
          </p>
          <p className="flex items-start font-diablo text-xs text-orange-200">
            <span className="mr-1">
              <StarIcon className="h-2 w-2 text-orange-200" />
            </span>
            Critical Strikes with Shred deal 54% [30 - 60]% of the damage dealt
            as Lightning damage to the target and surrounding enemies. (Druid
            Only)
          </p>
        </div>
        <p className="mt-2 text-right font-diablo text-xs text-gray-100">
          Requires Level 80
        </p>
      </div>
      <div className="mt-2 px-4 pb-3">
        <div>
          <h5 className="mb-2 text-center font-diablo text-xs uppercase  text-gray-100">
            Details
          </h5>
          <div className="grid grid-cols-2 gap-1">
            <div>
              <h6 className="text-center font-diablo text-xs uppercase">Bid</h6>
              <div>
                <p
                  className="flex h-[27px] w-[110px] items-center justify-center bg-cover bg-center font-diablo text-[10px]"
                  style={{
                    borderImage: "url('/images/borders/border.png') stretch",
                    borderImageWidth: "auto",
                    borderImageSlice: "100 0 0 0",
                  }}
                >
                  <CoinIcon className="mr-1 h-[8px] w-[8px] text-yellow-100" />
                  1000
                </p>
                <p className="text-center font-diablo text-xs text-gray-50">
                  11 Bids
                </p>
              </div>
            </div>
            <div>
              <h6 className="text-center  font-diablo text-[10px] uppercase">
                Buy
              </h6>
              <div>
                <p
                  className="flex h-[27px] w-[110px] items-center justify-center bg-cover bg-center font-diablo text-[10px]"
                  style={{
                    borderImage: "url('/images/borders/border.png') stretch",
                    borderImageWidth: "auto",
                    borderImageSlice: "100 0 0 0",
                  }}
                >
                  <CoinIcon className="mr-1 h-[8px] w-[8px] text-yellow-100" />
                  10.000.000
                </p>
                <p className="text-center font-diablo text-xs text-gray-50">
                  2h 21min left
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

GameCard.Title = Title;
GameCard.Separator = Separator;
GameCard.GreeaterAffixIcon = GreeaterAffixIcon;

function Title({ children }: React.PropsWithChildren): JSX.Element {
  return (
    <h2 className="pr-[30px] font-diablo text-sm text-orange-200">
      {children}
    </h2>
  );
}

function Separator({ className }: { className?: string }): JSX.Element {
  return (
    <hr
      className={twMerge(
        "h-1  border-none bg-[image:url('/images/separators/separator.png')] bg-contain bg-no-repeat",
        className,
      )}
    />
  );
}

function GreeaterAffixIcon(): JSX.Element {
  return (
    <i className="mr-1 block h-[10px] w-[10px] rounded-full  bg-[image:url('/images/decorations/greater-affix.webp')] bg-cover" />
  );
}
